import { render, staticRenderFns } from "./CardCustomer.vue?vue&type=template&id=e3ba2980&scoped=true&"
import script from "./CardCustomer.vue?vue&type=script&lang=js&"
export * from "./CardCustomer.vue?vue&type=script&lang=js&"
import style0 from "./CardCustomer.vue?vue&type=style&index=0&id=e3ba2980&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3ba2980",
  null
  
)

export default component.exports