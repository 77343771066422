<template>
  <van-popup v-model="showPopup" position="bottom" @close="closePopup">
    <div class="slot-popup">
      <div class="slot-popup-title">{{ actionType }}</div>
      <div class="line"></div>
      <div class="slot-popup-content">
        <slot></slot>
      </div>
      <div class="line"></div>
      <div class="slot-popup-btns">
        <van-button size="small" @click="cancelPopup">取 消</van-button>
        <van-button type="info" size="small" @click="confirmPopup">确 认</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    actionType: {
      type: String,
      default: () => "",
    },
    actionItem: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.showPopup = value;
      },
    },
  },
  mounted() {
  },
  methods: {
    // 关闭弹出层
    closePopup() {
      this.$emit("input", false);
      this.$emit("closePopup");
    },
    // 弹出层取消
    cancelPopup() {
      this.showPopup = false;
    },
    // 弹出层确认
    confirmPopup() {
      this.$emit("confirmPopup");
    },
  },
};
</script>

<style lang="less" scoped>
.slot-popup {
  .slot-popup-title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .slot-popup-content {
    padding: 24px 12px;

    /deep/ .van-cell {
      &.van-cell--required::before {
        padding: 4px 0px;
      }

      .van-field__label {
        margin-right: 0;
        width: unset;
        padding: 4px 0px;
      }

      .van-field__value {
        border: 1px solid #d3d6dc;
        padding: 4px 8px;
      }

      &.form-no-boder {
        .van-field__value {
          border: none;
        }
      }

      .van-uploader__upload {
        background: #f2f2f2;
      }
    }
  }

  .slot-popup-btns {
    padding: 12px 24px;
    display: flex;

    .van-button {
      flex: 1;
    }

    .van-button+.van-button {
      margin-left: 24px;
    }
  }

  .line {
    height: 1px;
    background: #d3d6dc;
  }
}
</style>
