const ENUM = {
    'getHomeStatusEnum': '/api/app/home/caseHandle/getHomeStatusEnum',
    'getHomeAdditionTypeEnum': '/api/app/home/caseHandle/getHomeAdditionTypeEnum', //家访补充类型枚举
    'getRelationEnum': '/api/app/home/caseHandle/getRelationEnum', // 关联关系
    'getDelayApplyStatusEnum': '/api/app/home/caseHandle/getDelayApplyStatusEnum',
    'getHomeCustomerConditionEnum': '/api/app/home/caseHandle/getHomeCustomerConditionEnum',
    'getHomeFollowStatusEnum': '/api/app/home/caseHandle/getHomeFollowStatusEnum',
    'getHomePassResultEnum': '/api/app/home/caseHandle/getHomePassResultEnum',
    'getAddrTypeEnum': '/api/app/home/caseHandle/case/customer/addr/type',
    'getRepaymentTypeEnum': '/api/app/home/caseHandle/case/customer/repayment/type',
    'getEffectiveTypeEnum': '/api/app/home/caseHandle/case/home/visit/effective/type',
    'getInstancyRelativeEnum': '/api/v4/ibp/customer/enum/getInstancyRelativeEnum',
};

export default ENUM