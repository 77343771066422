/**
 * @author Chenjunfan(chenjf@songcw.com)
 * @return {promise}
 */
import config from "@/config";
import axios from "@/libs/api.request";

const Api = {};

/**
 * @description 分页查询所有业务员
 * @param {string} operatorId 当前登录人
 * @param {integer} pageNo 页码
 * @param {integer} pageSize 每页大小
 */
Api.findListByOrg = (opts) => {
	opts.pageSize = config.pageSize;
	return axios.request({
		url: "/api/app/outsource/operator/findListByOrg",
		data: opts
	});
};

/**
 * @description 查询所有正常状态的业务员
 */
Api.findNormalList = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/normal/list",
		data: opts
	});

/**
 * @description 忘记密码
 * @param {string} mobile 手机号
 * @param {string} password 用户密码
 * @param {string} smsCode 短信验证码
 */
Api.forgetPwd = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/forgetPwd",
		data: opts
	});

/**
 * @description 密码登录
 * @param {string} loginWay 登录方式 PASSWORD
 * @param {string} mobile 手机号
 * @param {string} password 用户密码
 * @param {string} smsCode 短信验证码
 */

const authToken = window.btoa(
	`${config.auth.basicAuthUsername}:${config.auth.basicAuthPassword}`
);

Api.login = (opts) =>
	axios.request({
		url: "/login/token",
		data: opts,
		headers: {
			Authorization: `Basic ${authToken}`,
			platform: "FINANCE_OUTSOURCE_APP",
			"Content-Type": "application/x-www-form-urlencoded"
		}
	});

/**
 * @description 验证码登录
 */
Api.loginSms = (opts) =>
	axios.request({
		url: "/login/sms/token",
		data: opts,
		headers: {
			Authorization: `Basic ${authToken}`,
			platform: "FINANCE_OUTSOURCE_APP",
			"Content-Type": "application/x-www-form-urlencoded"
		}
	});

/**
 * @description 登录,openId绑定
 * @param {string} openId
 * @param {string} mobile
 */
Api.bindOpenId = (openId) =>
	axios.request({
		url: `/api/app/outsource/operator/bindOpenId/${openId}`
	});

/**
 * @description 注销登录,解绑openId
 */
Api.unbindOpenId = () =>
	axios.request({
		url: "/api/app/outsource/operator/unbindOpenId"
	});

/**
 * @description 退出登录
 */
Api.logout = () =>
	axios.request({
		url: "/logout/token"
	});

/**
 * @description 业务员注册
 * @param {string} name 名字
 * @param {string} mobile 手机号
 * @param {string} orgNo 机构编号
 * @param {string} password 用户密码
 */
Api.operatorRegister = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/register",
		data: opts
	});

/**
 * @description 发送短信验证码
 * @param {string} mobile 手机号
 * @param {string} smsCodeType 机构编号 <LOGIN, FORGET_PWD>
 */
Api.sendValidateCode = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/sendValidateCode",
		data: opts
	});

/**
 * @description 修改用户状态
 * @param {string} operatorId 操作员id
 * @param {string} status 用户状态 <ON, OFF>
 */
Api.operatorUpdateStatus = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/updateStatus",
		data: opts
	});

/**
 * @description 上传
 * @param {string} xxx xxx
 */
Api.uploadFile = (opts) =>
	axios.request({
		url: "/api/v4/nicp/files/file",
		data: opts
	});

/**
 * @description 根据用户手机号查询业务员信息
 */
Api.getFindByMobile = (mobile) =>
	axios.request({
		url: `/api/app/outsource/operator/findByMobile?mobile=${mobile}&type=HOME`,
		method: "GET",
		headers: {
			Authorization: `Basic ${authToken}`,
			platform: "FINANCE_OUTSOURCE_APP"
		}
	});
/**
 * @description 修改用户状态
 * @param {string} operatorId 操作员id
 * @param {string} status 用户状态 <ON, OFF>
 */
Api.operatorUpdateStatus = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/updateStatus",
		data: opts
	});

Api.operatorUpdateAssignFlag = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/updateAssignFlag",
		data: opts
	});

/**
 * @description 业务员注册
 * @param {string} name 名字
 * @param {string} mobile 手机号
 * @param {string} orgNo 机构编号
 * @param {string} password 用户密码
 */
Api.operatorRegister = (opts) =>
	axios.request({
		url: "/api/app/outsource/operator/register",
		data: opts
	});

/**
 * @description 获取用户基本信息
 * @param {Object} opts
 * @param {Boolean} refresh 是否重新请求
 */

Api.baseInfo = (refresh = false) => {
	const userInfo = {
		data:
			localStorage.getItem("userAccount") &&
			JSON.parse(localStorage.getItem("userAccount")),
		success: true
	};
	if (userInfo.data && !refresh) {
		return new Promise((resolve) => resolve(userInfo));
	}
	return new Promise((resolve) => {
		axios
			.request({
				url: "/api/app/outsource/operator/findByOperatorId",
				method: "GET"
			})
			.then((res) => {
				// console.log(res);
				// debugger;
				if (res.success) {
					resolve(res);
					localStorage.setItem("userAccount", JSON.stringify(res.data));
				}
			});
	});
};

/**
 * @description 中止类型枚举
 */
Api.getDispatchedSuspendTypeEnum = () =>
	axios.request({
		url:
			"/api/v4/ibp/outsource/commonDispatchedSuspend/getDispatchedSuspendTypeEnum",
		method: "GET"
	});

/**
 * @description 查询配置
 */
Api.commonConfigDictFindListPost = (data) =>
	axios.request({
		url: "/api/v4/ibp/outsource/commonConfigDict/findList",
		data
	});

/**
 * @description 天地图行政区划区域搜索
 */
Api.skyMapSearch = (data) =>
	axios.request({
		url: `/api/app/home/caseHandle/case/home/visit/locations/search`,
		data: {
			level: 12,
			mapBound: "116.02524,39.83833,116.65592,39.99185",
			queryType: "7",
			start: 0,
			count: 10,
			...data
		}
	});

/**
 * @description 上门地址查询
 */
Api.skyMapLocationsList = (data = {}) =>
	axios.request({
		url: `/api/app/home/caseHandle/case/home/visit/locations/list`,
		data
	});

/**
 * @description 家访日历统计
 */
Api.skyCalendarStatistics = (data = {}) =>
	axios.request({
		url: `/api/app/home/caseHandle/case/home/visit/calendar/statistics`,
		data
	});

/**
 * @description 调度中心家访记录列表
 */
Api.findPageISO = (data) =>
	axios.request({
		url: "/api/app/home/caseHandle/findPageISO",
		data
	});

/**
 * @description 根据projectCode查询处理中家访记录
 */
Api.getProcessHomeByProjectcode = (projectcode) =>
	axios.request({
		url: `/api/app/home/caseHandle/case/home/getProcessHome/${projectcode}`,
		method: "GET"
	});

/**
 * @description 根据projectCode查询待中止业务状态查询
 */
Api.getSuspendBizStatus = (projectCode) =>
	axios.request({
		url: `/api/app/home/caseHandle/case/home/getSuspendBizStatus/${projectCode}`,
		method: "GET"
	});

export default Api;
