var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"case"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"l"},[_vm._v(" "+_vm._s(_vm.item.applyBizNo)+" - "+_vm._s(_vm._f("dash")(_vm.item.customerName))+" ")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"attrs"},[_c('div',[_vm._v("家访区域："+_vm._s(_vm.item.homeVisitArea))]),_c('div',[_vm._v("建议时间段："+_vm._s(_vm._f("dash")(_vm.item.homeVisitTimeInterval)))]),_c('div',[_vm._v("备注："+_vm._s(_vm._f("dash")(_vm.item.dispatchedOpinion)))]),('ROLLBACK' == _vm.item.latestResults && _vm.item.latestReason)?_c('div',[_vm._v(" 驳回原因："+_vm._s(_vm._f("dash")(_vm.item.latestReason))+" ")]):_vm._e(),_c('div',[_vm._v("家访时间："+_vm._s(_vm.item.homeVisitDate))]),(
          ['APPROVALING', 'PROCESSING'].includes(_vm.item.status) &&
            _vm.item.expireTime
        )?_c('div',[_vm._v(" 剩余时间："),_c('span',{staticClass:"danger"},[_vm._v(_vm._s(_vm.calDay(_vm.item.expireTime))+"天")])]):_vm._e()]),_c('div',{staticClass:"status"},[_c('span',{class:{PROCESSING: 'warning',APPROVALING: 'warning',FINISHED: 'success',OVERDUE_CANCEL: 'danger',CANCELED: 'danger'}[_vm.item.status]},[_vm._v(" "+_vm._s(_vm._f("enumMessage")(_vm.item.status,_vm.getHomeStatusEnum)))])]),(_vm.item.iouStatus == 'CLEAR')?_c('div',{staticClass:"tag"},[_vm._v("已结清")]):_vm._e(),(_vm.item.status == 'PROCESSING' && _vm.item.latestResults == 'ROLLBACK')?_c('div',{staticClass:"tag warning"},[_vm._v(" 驳回 ")]):_vm._e()]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"tips"},[(
          _vm.item.unFeedbackDay > 0 &&
            !['FINISHED', 'OVERDUE_CANCEL'].includes(_vm.item.status)
        )?_c('span',{staticClass:"danger"},[_c('i',{staticClass:"iconfont icon-infofill"}),_vm._v(_vm._s(_vm.item.unFeedbackDay)+"天未反馈")]):_vm._e()]),_c('div',{staticClass:"buttons"},[(['PROCESSING'].includes(_vm.item.status) && _vm.isShowBtn)?_c('button',{staticClass:"expire",on:{"click":function($event){$event.stopPropagation();return _vm.onActionClick('中止', _vm.item)}}},[_vm._v(" 中止 ")]):_vm._e(),('PROCESSING' == _vm.item.status && _vm.isShowBtn)?_c('button',{staticClass:"expire",on:{"click":function($event){$event.stopPropagation();return _vm.onActionClick('改期', _vm.item)}}},[_vm._v(" 改期 ")]):_vm._e(),(_vm.applicationForRenewal)?_c('button',{staticClass:"expire",on:{"click":function($event){$event.stopPropagation();return _vm.onActionClick('DelayRequest', _vm.item)}}},[_vm._v(" 续期申请 ")]):_vm._e(),(
          ['APPROVALING', 'PROCESSING'].includes(_vm.item.status) && _vm.isShowBtn
        )?_c('button',{staticClass:"feedback",on:{"click":function($event){$event.stopPropagation();return _vm.onActionClick('FeedBackRequest', _vm.item)}}},[_vm._v(" 反馈 ")]):_vm._e(),('PROCESSING' == _vm.item.status && _vm.isShowBtn)?_c('button',{staticClass:"close",on:{"click":function($event){$event.stopPropagation();return _vm.onActionClick('家访', _vm.item)}}},[_vm._v(" 家访 ")]):_vm._e(),('PROCESSING' == _vm.item.status && _vm.isAdmin && !_vm.isShowBtn)?_c('button',{staticClass:"close",on:{"click":function($event){$event.stopPropagation();return _vm.goDetail(_vm.item)}}},[_vm._v(" 详情 ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }