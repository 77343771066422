<template>
  <van-icon name="user-o" color="#1989fa" size="25px" @click="goMine" />
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goMine() {
      this.$router.push({
        name: "Mine",
      });
    },
  },
};
</script>