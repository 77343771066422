<template>
  <div class="customer">
    <div class="title">
      <span>{{ data.customerName }}</span
      ><span><a :href="`tel:${data.mobile}`">{{ data.mobile }}</a></span
      ><span>{{ data.merchantRegion }}</span>
    </div>
    <div class="row">
      身份证：{{ data.certNo | sensitiveField(6, 4) }}
    </div>
    <div class="row">户籍地址：{{ data.permanentzAddress }}</div>
    <div class="row">居住地址：{{ data.address }}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="less" scoped>
.customer {
  background: #fff;
  padding: 5px;
  color: #333333;
  font-size: 13px;
  .title {
    font-weight: 650;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .title,
  .row {
    line-height: 1.6em;
  }
}
</style>
