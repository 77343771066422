<template>
  <van-tabbar
    :value="$route.name"
    active-color="#3C86FF"
    inactive-color="#787B84"
  >
    <van-tabbar-item name="Home" to="/home">
      <template #icon>
        <i class="iconfont icon-home"></i>
      </template>
      首页</van-tabbar-item
    >
    <van-tabbar-item name="List" to="/list">
      <template #icon>
        <i class="iconfont icon-Case"></i> </template
      >案件</van-tabbar-item
    >
    <van-tabbar-item name="Mine" to="/mine">
      <template #icon>
        <i class="iconfont icon-w_mine"></i>
      </template>
      我的</van-tabbar-item
    >
  </van-tabbar>
</template>
<script>
export default {
  computed: {},
};
</script>
<style lang="less" scoped>
  .iconfont{
    font-size: 24px;
  }
</style>