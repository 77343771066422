import { mapActions, mapState } from 'vuex'
import store from './store'

const mapStateEnums = {
}

Object.keys(store.state.enums).forEach((key) => {
  mapStateEnums[key] = state => state.enums[key]
})


export default {
  components: {

  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      ...mapStateEnums,
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchEnums_Raw: 'fetchEnums'
    }),
    async fetchEnums(...enums) {
      return await this.fetchEnums_Raw(enums)
    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
