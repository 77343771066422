import { getUserInfo } from '@/libs/utils'
import config from '@/config'

// 配置项
const DEFAULTS = {
  text: '', // 文本内容
  opacity: 0.15, // 透明度
  rotate: 20, // 倾斜角度
  size: 14, // 大小
  zIndex: 1,
};

export default (el, options = {}) => {
  // 需要保证当前调用元素的 position 属性为 absolute、relative 或 fixed
  const { position } = el.style;
  if (!['absolute', 'relative', 'fixed'].includes(position)) {
    el.style.position = 'relative';
  }

  // 参数
  const $options = {
    ...DEFAULTS,
    ...options,
  };

  // 是否可以创建水印
  if (!options.text) {
    try {
      // 获取用户信息
      const user = getUserInfo();
      const phone = (user.username || '').split(",")[0]
      $options.text = `${user.realName}-${phone.slice(-4)}`;
    } catch (error) {
      console.log(error);
      $options.text = config.title;
    }
  }

  // 移除已有watermark
  const watermarkDom = document.getElementById("watermarkId");
  watermarkDom && watermarkDom.remove();

  // 画布的尺寸
  const rotate = $options.rotate * Math.PI / 180;
  const width = Math.min($options.text.length * $options.size, 200);
  const height = width;

  // 创建水印图片 ： 创建画布 - 绘制内容 - 获取图片数据 - 显示为背景
  const canvas = document.createElement('canvas');
  canvas.width = width * 3;
  canvas.height = height * 3;
  const ctx = canvas.getContext('2d');
  // ctx.fillStyle = '#eee';
  // ctx.fillRect(0, 0, canvas.width, canvas.height);
  // ctx.strokeRect(0, 0, canvas.width, canvas.height);
  ctx.rotate(-rotate);
  ctx.translate(-70, 70);
  ctx.font = `${$options.size * 2}px Arial`;
  ctx.fillStyle = 'black'; // 字体颜色
  ctx.textAlign = 'end';
  ctx.fillText($options.text, canvas.width, canvas.height / 4);
  ctx.textAlign = 'start';
  ctx.fillText($options.text, 0, canvas.height - canvas.height / 3);
  // ctx.fillStyle = '#ddd';
  // ctx.fillRect(0, 0, canvas.width, canvas.height);

  // 追加元素
  const watermark = document.createElement('div');
  watermark.className= 'watermarkClass';
  watermark.id= 'watermarkId';
  watermark.style.position = 'absolute';
  watermark.style.bottom = 0;
  watermark.style.left = 0;
  watermark.style.right = 0;
  watermark.style.top = 0;
  watermark.style.zIndex = $options.zIndex;
  watermark.style.opacity = $options.opacity;
  watermark.style.background = `url("${canvas.toDataURL('image/png')}") repeat`;
  watermark.style.backgroundSize = `${canvas.width / 2}px ${canvas.height / 2}px`;
  watermark.style.pointerEvents = 'none';
  el.appendChild(watermark);
};
