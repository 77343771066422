
import basic from './basic'
import list from './list'


// 功能模块
const modules = [
  ...list,
];

let routes = {
  basic,
  modules,
};

// 全部导出
export default routes;
