import CardCustomer from './CardCustomer'
import Uploader from './Uploader'
import UserIcon from './UserIcon'
import TabBarNav from './TabBar'
import CaseItem from './CaseItem'
import CaseCard from './CaseCard'
import CaseGps from './CaseGps'
import CaseRecord from './CaseRecord'
import SubTitle from './SubTitle'
import PopupForm from './PopupForm'
import PopupSlot from './PopupSlot'

export default {
    install(Vue) {
        Vue.component('CardCustomer', CardCustomer)
        Vue.component('Uploader', Uploader)
        Vue.component('UserIcon', UserIcon)
        Vue.component('TabBarNav', TabBarNav)
        Vue.component('CaseItem', CaseItem)
        Vue.component('CaseCard', CaseCard)
        Vue.component('CaseGps', CaseGps)
        Vue.component('CaseRecord', CaseRecord)
        Vue.component('SubTitle', SubTitle)
        Vue.component('PopupForm', PopupForm)
        Vue.component('PopupSlot', PopupSlot)
    }
};