import Vue from 'vue'
import Vuex from 'vuex'
import ENUM from '@/api/enum'
import storage from '@/libs/storage'
import axios from '@/libs/api.request';

Vue.use(Vuex)

const ENUM_KEY = 'ENUM_CACHE'

const _enums = storage.getSession(ENUM_KEY) || {}

Object.keys(ENUM).forEach((key) => {
  _enums[key] = _enums[key] || []
})

export default new Vuex.Store({
  state: {
    enums: _enums
  },
  mutations: {
    SET_ENUMS() {
      this.state.enums = {
        ..._enums
      }
      storage.setSession(ENUM_KEY, _enums)
    }
  },
  actions: {
    async fetchEnums({ commit }, enumName) {
      if (!enumName || !enumName.length) {
        return
      }
      if (Array.isArray(enumName[0])) {
        enumName = enumName[0]
      }
      return await Promise.all(enumName.map(async (key) => {
        if (_enums[key] && _enums[key].length) {
          return _enums[key]
        }
        const url = ENUM[key]
        if (!url) {
          return
        }
        const res = await axios.request({
          url,
          method: 'get'
        })
        _enums[key] = res.data
        commit('SET_ENUMS')
        return res.data
      }))
    },
  },
  modules: {
  }
})
