<template>
  <div class="sub_title_container" :class="size">
    <div class="sub_title_text">{{ title }}</div>
    <div class="sub_title_btns">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.sub_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.small {
    .sub_title_text {
      font-size: 14px;
      font-weight: normal;
      height: 32px;
      line-height: 32px;
    }
  }

  .sub_title_text {
    font-size: 16px;
    font-weight: bold;
    height: 36px;
    line-height: 36px;
  }

  .sub_title_btns {
  }
}
</style>
