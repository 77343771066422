<template>
	<div class="case">
		<div class="top">
			<div class="l">
				{{ item.applyBizNo }} - {{ item.customerName | dash }}
			</div>
			<!-- <div class="r">
        客户：{{ item.customerName | dash }} - 
      </div> -->
		</div>
		<div class="content">
			<div class="attrs">
				<div>家访区域：{{ item.homeVisitArea }}</div>
				<div>建议时间段：{{ item.homeVisitTimeInterval | dash }}</div>
				<div>备注：{{ item.homeReason | dash }}</div>
				<div>家访人员：{{ item.candidateName | dash }}</div>
				<div>派单时间：{{ item.dispatchedTime | dash }}</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="footer">
			<div class="tips"></div>
			<div class="buttons">
				<button class="close" @click.stop="goDetail(item)">
					详情
				</button>
				<button
					v-if="orderStatus == '0'"
					class="close"
					@click.stop="onActionClick('退单', item)"
				>
					退单
				</button>
				<button
					v-if="orderStatus == '0'"
					class="close"
					@click.stop="onActionClick('派单', item)"
				>
					派单
				</button>
				<button
					v-if="orderStatus == '1'"
					class="close"
					@click.stop="onActionClick('改派', item)"
				>
					改派
				</button>
				<button
					v-if="orderStatus == '2' && canTakeOrders(item)"
					class="close"
					@click.stop="onActionClick('接单', item)"
				>
					接单
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import { getUserInfo } from "@/libs/utils";
export default {
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		orderStatus: {
			type: String,
			default: () => ""
		}
	},
	data() {
		return {
			userInfo: getUserInfo()
		};
	},
	methods: {
		onActionClick(action, item) {
			this.$emit("actionClick", action, item);
		},

		// 详情
		goDetail(item) {
			this.$emit('setScrollY')
			this.$router.push({
				name: "CaseDetail",
				params: {
					homeNo: item.homeNo
				},
				query: { bizNo: item.applyBizNo, readonly: true }
			});
		},
		// 是否能接单
		canTakeOrders(item) {
			const powers = item.candidate?.split(",");
			const id = this.userInfo.userId;
			return powers.includes(id);
		}
	},
	mounted() {}
};
</script>
<style lang="less" scoped>
.success {
	color: #07c160;
}

.danger {
	color: #ff4848;
}

.warning {
	color: #fa9540;
}

.case {
	background: #ffffff;
	border-radius: 4px;
	position: relative;
	user-select: text;

	&::before {
		content: " ";
		position: absolute;
		left: 0px;
		top: 12px;
		width: 4px;
		height: 32px;
		background: #3c86ff;
		border-radius: 0 100px 100px 0px;
	}

	.top {
		padding: 18px 17px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 14px;
		letter-spacing: 0;
		color: #2b2e32;

		.l {
			font-weight: bold;
		}

		.r {
			font-weight: 500;
		}
	}

	.content {
		position: relative;
		display: flex;

		.attrs {
			flex: 1;
			padding: 0px 16px 18px 17px;

			div {
				color: #787b84;
				font-size: 12px;
				line-height: 1.8em;
			}
		}

		.status {
			font-size: 12px;
			letter-spacing: 0;
			font-weight: 500;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding: 0px 20px 0px 0px;
		}

		.tag {
			position: absolute;
			right: 0px;
			bottom: 10px;
			padding: 8px 10px 8px 16px;
			background: #7bd17c;
			border-radius: 100px 0 0 100px;
			font-size: 12px;
			color: #ffffff;
			letter-spacing: 0;

			&.warning {
				background: #fa9540;
			}
		}
	}

	.line {
		height: 1px;
		background: #f1f2f7;
	}

	.footer {
		display: flex;
		justify-content: space-between;
		height: 47px;
		line-height: 47px;
		font-size: 12px;

		.tips {
			padding-left: 15px;

			.iconfont {
				margin-right: 5px;
				font-size: 13px;
			}
		}

		.buttons {
			button {
				font-size: 12px;
				letter-spacing: 0;
				font-weight: 500px;
				border: 1px solid currentColor;
				border-radius: 2px;
				padding: 7px 18px;
				margin-right: 9px;
				background: transparent;
				box-sizing: border-box;

				&.expire {
					color: #ff9f00;
				}

				&.feedback {
					color: #4cb84c;
				}

				&.close {
					background: #3c86ff;
					color: #fff;
					border-color: #3c86ff;
				}
			}
		}
	}
}
</style>
