import * as _ from 'lodash'
import dayjs from 'dayjs'

/**
 *格式化时间
 *yyyy-MM-dd hh:mm:ss
 */
export function formatDate(time, fmt) {
  if (time === undefined || '') {
    return
  }
  const date = new Date(time)
  if (/(y+)/i.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    '[dD]+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}
/*
 * 隐藏用户手机号中间四位
 */
export function hidePhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}

/**
 * 根据枚举 value 返回 displayName
 * @param {*} value
 * @param {*} enums
 */
export const enumMessage = (value, enums) => {
  if (!enums || !enums.length) {
    return value
  }
  for (const $enum of enums) {
    if ($enum.value === value) {
      return $enum.displayName
    }
  }
  return value
}

/**
 * 默认横杠
 * @param {*} value
 */
export const dash = (value, placeholder = '-') => {
  if (value == null || _.trim(value) === '') {
    return placeholder
  }
  return value
}

/**
 * 日期格式化
 * @description 基于 moment 的日期格式化
 * @param {Number|String|Date} value
 * @param {String} format 更多配置参考 moment 文档 <https://momentjs.com/docs/>
 * @param {String} unlimitedText
 * @returns String
 */
export const dateFormat = (
  value,
  format = 'YYYY-MM-DD HH:mm:ss',
) => {
  if (!value) return ''
  value = typeof value === 'string' ? value.replace(/-/g, '/') : value
  const date = dayjs(value)
  return date.format(format)
}

/**
 * 千分位数字格式化
 * @param {Number|String} value
 * @param {int} fixed 修正位数
 * @returns {null|String}
 */
export const amount = (value, fixed = 2) => {
  if ([null, undefined, ''].includes(value)) return null
  if (value === 0) return parseFloat(0).toFixed(fixed)

  let num = value.toString().replace(/,/g, '')

  num = parseFloat(num || 0)
  num = num.toFixed(+fixed || 2).toString()
  num = num.split('.')

  return [num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'), num[1]].join('.')
}


/**
 * 公共脱敏方法
 * @param {*} field 要脱敏的字段
 * @param {*} before 开头未脱敏字符数
 * @param {*} after 结尾未脱敏字符数
 */
 export const sensitiveField = (field, before = 3, after = 4) => {
  if (!field) {
    return '';
  }
  const replace = "*"
  field = String(field);

  // 匹配中文、英文、数字
  const regItem = '[\u4e00-\u9fa5a-zA-Z0-9]';
  const regExp = `(${regItem}{${before}})${regItem}*(${regItem}{${after}})`;
  const reg = new RegExp(regExp);

  return field.replace(reg, `$1${replace.repeat(Math.max(field.length - before - after, 0))}$2`);
}