import axios from 'axios';
import qs from 'qs';
import { getToken, clearUserInfo } from '@/libs/utils'

const loginFilterList = [
  '/api/app/outsource/operator/sendValidateCode',
];

// 需要把pageSize pageNo 放在把bady里
const pageList = [
  '/api/app/outsource/operator/findListByOrg'
];

class HttpRequest {
  constructor(configs) {
    this.baseUrl = configs.baseUrl;
  }

  getInsideConfig() {
    const tokenHeader = {};
    const token = getToken()
    if (token) {
      tokenHeader['Authorization'] = `Bearer ${token}`
    }

    const config = {
      baseURL: this.baseUrl,
      defaults: {
        withCredentials: true,
      },
      // transformRequest: [data => qs.stringify(data)],
      headers: {
        post: {
          'Content-Type': 'application/json;',
        },
        platform: 'FINANCE_OUTSOURCE_APP',
        ...tokenHeader
      },
    };
    return config;
  }

  interceptors(instance) {
    // 请求拦截
    instance.interceptors.request.use((config) => {
      if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.transformRequest = [data => qs.stringify(data)];
      }
      // 接口-登录过滤
      loginFilterList.includes(config.url) && delete (config.headers.Authorization);

      const MOVE_TO_PARAMS_KEY = [
        'pageNo',
        'pageSize'
      ]

      if (!pageList.includes(config.url)) {
        if (config.data) {
          config.params = config.params || {}
          for (const key of MOVE_TO_PARAMS_KEY) {
            if (typeof config.data[key] !== 'undefined') {
              config.params[key] = config.data[key]
              delete config.data[key]
            }
          }
        }
      }

      return config;
    }, error => Promise.reject(error));

    // 响应拦截
    instance.interceptors.response.use((res) => {
      res.data.success = (res.data.code === '0000');
      if (!res.data.success && res.data.message) {
        throw res.data.message;
      }
      return res.data;
    }, (error) => {
      const pathName = window.location.pathname;
      // 未登录 无权限
      if ([401].includes(error.response.status) && pathName !== '/login') {
        clearUserInfo();
        // 此处需要每次跳转到微信授权页面，以免无法获取客户端 openId
        localStorage.setItem('unauthed_redirect', pathName);
        window.location.href = '/login';
      } else if (error.response.data.message) {
        throw error.response.data.message;
      } else {
        throw '网络不佳~请稍后重试~';
      }
      return error.response.data;
    });
  }

  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), {
      method: 'POST',
    }, options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;
