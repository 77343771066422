<template>
  <div class="case">
    <div class="top" :class="{
      '正常': 'success',
      '异常': 'danger',
    }[item.gpsStatus]">
      {{ item.deviceType }} - {{ item.gpsStatus }}
    </div>
    <div class="content">
      <div class="attrs">
        <div>设备号：{{ item.imei | dash }}</div>
        <div>运行状态：{{ item.runStatus | dash }}</div>
        <div>状态更新时间：{{ item.receiveTime | dash }}</div>
        <div>地址：{{ item.address | dash }}</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="footer">
      <div class="tips"></div>
      <div class="buttons">
        <button class="close" @click.stop="onActionClick('查看定位', item)">
          查看定位
        </button>
        <button class="close" @click.stop="onActionClick('查看跟踪', item)">
          查看跟踪
        </button>
        <button class="close" @click.stop="onActionClick('查看轨迹', item)">
          查看轨迹
        </button>
        <button class="close" @click.stop="onActionClick('导航', item)">
          导航
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {};
  },
  methods: {
    onActionClick(action, item) {
      this.$emit("actionClick", action, item);
    },
  },
  mounted() { },
};
</script>
<style lang="less" scoped>
.case {
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  user-select: text;

  .top {
    padding: 12px;
    font-size: 14px;
    letter-spacing: 0;
    background-color: #aaa;
    color: #fff;
    text-align: center;

    &.danger {
      background-color: #ff4848;
    }

    &.success {
      background-color: #4cb84c;
    }
  }

  .content {
    position: relative;
    display: flex;

    .attrs {
      flex: 1;
      padding: 0px 16px 18px 17px;

      div {
        color: #787b84;
        font-size: 12px;
        line-height: 1.8em;
      }
    }
  }

  .line {
    height: 1px;
    background: #f1f2f7;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    height: 47px;
    line-height: 47px;
    font-size: 12px;

    .tips {
      padding-left: 15px;

      .iconfont {
        margin-right: 5px;
        font-size: 13px;
      }
    }

    .buttons {
      button {
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 500px;
        border: 1px solid currentColor;
        border-radius: 2px;
        padding: 7px 12px;
        margin-right: 9px;
        background: transparent;
        box-sizing: border-box;

        &.expire {
          color: #ff9f00;
        }

        &.feedback {
          color: #4cb84c;
        }

        &.close {
          background: #3c86ff;
          color: #fff;
          border-color: #3c86ff;
        }
      }
    }
  }
}
</style>
